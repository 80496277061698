.container {
  display: flex;
  flex-direction: column;
  padding: 0 100px 30px;

  input[type='number'] {
    -moz-appearance: textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
}

.section-title {
  display: flex;
  justify-content: center;
  font-size: 26px;
  font-weight: bold;
  line-height: 1.3;
  margin: 26px 0;
}

.container .form-wrap {
  display: flex;
}

.container .form-wrap .form {
  display: flex;
  flex-direction: column;
  width: 30%;
  margin: 9px 0 30px 50px;
}

.MuiInput-underline:hover:not(.Mui-disabled):before {
  border: none;
}

.label {
  margin-bottom: 10px;
}

.date-field.date-field {
  margin-bottom: 25px;
}

.field.field {
  font-family: 'Open Sans';
  font-weight: normal;
  font-size: 12px;
  color: #5d5d5d;
  border-radius: 4px;
  margin-bottom: 25px;
}

.input.input {
  padding: 12px 14px;
  font-size: 14px;
}

.textarea.textarea {
  font-size: 14px;
}

.btn.btn {
  display: flex;
  background: #066bd6;
  color: #fff;
  margin-top: 10px;

  &:hover {
    background: #06bb9b;
  }
}

.cards-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}

.cards-wrapper .section-title {
  display: flex;
  justify-content: center;
  font-size: 26px;
  font-weight: bold;
  line-height: 1.3;
  margin: 26px 0;
}

.card {
  & {
    display: flex;
    flex-direction: column;
    max-width: 300px;
    padding: 20px;
    margin: 0 30px 30px 0;
  }

  .text-field {
    margin-bottom: 15px;
  }

  .text-field > .text {
    font-size: 14px;
    line-height: 1.15;
    color: #000000;
  }

  .text-field > .label {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.15;
    margin-right: 10px;
    color: #bebebe;
  }
}
