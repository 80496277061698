.row {
  display: flex;
  flex-direction: row;
}

.chat {
  width: 75%;
  margin-right: 15px;
  border-right: 1px solid lightgray;
  height: 100%;
}

.chat-link {
  position: absolute;
  right: 22vw;
  width: 15%;
  height: 63px;

  &:hover {
    opacity: 0.5;
  }

  .link-a {
    display: block;
    text-decoration: none;
    text-align: center;
    width: 100%;
    height: 100%;
  }

  .user-name {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 35%;
    color: #037df9;
  }

  .MuiAvatar-root {
    position: relative;
    top: 5px;
    left: 5px;
    margin-right: 5px;
  }
}

.chat-link:hover {
  opacity: 0.9;
}

.search {
  width: 301px;

  .search-header {
    padding: 0.8rem 1rem;
    background-color: #ececec;
    border: 1px solid #d4d4d4;
  }

  .search-input {
    border: 0px;
    height: 35px;
    width: 93%;
    padding-left: 15px;
    border-radius: 50px;
  }

  .search-list {
    border-left: 1px solid #d4d4d4;
    border-right: 1px solid #d4d4d4;
  }

  .search-item {
    padding-left: 14px;
    height: 71px;
    color: #525252;
    text-decoration: none;
    border: none;
    border-bottom: 1px solid #d4d4d4;
    background-color: transparent;
    overflow: hidden;
    display: flex;
    align-items: center;
    word-wrap: break-word;
    transition: color 0.1s ease-in-out, background-color 0.1s ease-in-out;

    &:hover {
      cursor: pointer;
    }
  }

  .search-avatar {
    margin-right: 10px;
  }

  .search-name {
    font-weight: 700;
    font-size: 80%;
    white-space: pre;
    max-height: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .search-email {
    font-size: 75%;
    white-space: pre;
    max-height: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
