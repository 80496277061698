.management-calendar {
  &__colors {
    display: flex;

    &-item {
      display: flex;
      align-items: center;
      margin: 14px 14px 14px 0;

      &__block {
        width: 30px;
        height: 30px;
        background-color: currentColor;
        margin-right: 10px;
      }
    }
  }
}

.DayPicker {
  display: flex;
}

.DayPicker-wrapper {
  outline: none;
}

.DayPicker-NavButton {
  top: 0;
  right: 16px;
  margin: 0;
}

.DayPicker-NavButton--next {
  //background: url(${NextIcon});
}

.DayPicker-NavButton--prev {
  //background: url(${PrevIcon});
  margin-right: 1.5em;
}

.DayPicker-Caption {
  margin-bottom: 60px;
  padding: 0;
}

.DayPicker-Caption > div {
  color: black;
  font-family: 'Open Sans', 'sans-serif';
  font-size: 24px;
  font-weight: 600;
  line-height: 20px;
}

.DayPicker-Month {
  border-collapse: separate;
  border-spacing: 2px 2px;
  margin: 0;
}

.DayPicker-Weekday {
  color: gray;
  font-family: 'Open Sans', 'sans-serif';
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  text-align: left;
  padding: 0 0 15px 0;
}

.DayPicker-Day {
  padding: 0;
  width: 80px;
  height: 80px;
  box-sizing: border-box;
  color: black;
}

/* dateStyle*/
.DayPicker-Day > div > div {
  position: absolute;
  color: #303133;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 12px;
  top: 8px;
  left: 8px;
}

.DayPicker-Day--outside {
  border: none !important;
  background-color: rgba(196, 196, 196, 0.43) !important;
  border-radius: 0;
  opacity: 0 !important;
}

.DayPicker-Day--disabled {
  background-color: rgba(196, 196, 196, 0.43);
  border-radius: 0;
  opacity: 0.5;
}

.DayPicker-Day--booked {
  background-color: rgba(0,115,249,0.3);
  border-radius: 0;
}

.DayPicker-Day--rent {
  background-color: rgba(253, 203, 7, 0.3);
  border-radius: 0;
}

.DayPicker-Day:focus {
  outline: none;
}

.DayPicker-Day:not(.DayPicker-Day--disabled) {
  border: 2px solid #06bb9b;
  background-color: #fff;
  border-radius: 0;
}

.DayPicker-Day--blocked {
  border: 2px solid #e24c4c;
  background-color: rgba(226, 76, 76, 0.1);
  opacity: 0.5;
}
